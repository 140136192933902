import axios from 'axios'
import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet';
import AppURL from '../api/AppURL'
import HomeAboutUs from '../components/home/main/HomeAboutUs';
import HomeBlog from '../components/home/main/HomeBlog';
import HomeBrandLogos from '../components/home/main/HomeBrandLogos';
import HomeClients from '../components/home/main/HomeClients';
import HomeFaqs from '../components/home/main/HomeFaqs';
import HomeFeatures from '../components/home/main/HomeFeatures';
import HomeGallery from '../components/home/main/HomeGallery';
import HomeLatestNews from '../components/home/main/HomeLatestNews';
import HomeServices from '../components/home/main/HomeServices';
import HomeSlider from '../components/home/main/HomeSlider';
import HomeTeam from '../components/home/main/HomeTeam';
import HomeTestimonial from '../components/home/main/HomeTestimonial';
import HomeVideo from '../components/home/main/HomeVideo';
import SuccessProfile from '../components/home/main/SuccessProfile';



export class Home extends Component {
  constructor() {
    super();
    this.state = {
      seo_title: "",
      seo_description: "",
      seo_keywords: "",
      page_name: ""

    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.GetVisitorDetails();

    axios.post(AppURL.GetStaticSeo("Home")).then(response => {
      let StatusCode = response.status;
      if (StatusCode == 200) {
        let JsonData = (response.data['Seo'])[0];
        this.setState({
          seo_title: JsonData['seo_title'],
          seo_description: JsonData['seo_description'],
          seo_keywords: JsonData['seo_keywords'],
          page_name: JsonData['page_name'],

        });
      }

    }).catch(error => {

    });

  }

  GetVisitorDetails = () => {
    axios.get(AppURL.VisitorDetails).then().catch()

  }

  render() {

    return (
      <Fragment>
        <Helmet>
          <title>{this.state.seo_title}</title>
          <meta name="description" content={this.state.seo_description} />
          <meta name="keywords" content={this.state.seo_keywords} />
          <link rel="canonical" href={this.state.page_name} />
        </Helmet>
        <HomeSlider />
        <HomeAboutUs />
        <HomeServices />
        <SuccessProfile />
        <HomeFeatures />
        <HomeTeam />
        <HomeGallery />
        <HomeClients />
        <HomeTestimonial />
        <HomeBrandLogos />
        <HomeVideo />
        <HomeBlog />
        <HomeLatestNews />
        <HomeFaqs />
      </Fragment>
    )
  }
}

export default Home;
