import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Logo from './Logo';
import AppURL from '../../api/AppURL';
import axios from 'axios';

class LogoWithAddress extends Component {
    constructor() {
        super();
        this.state = {
            contact_number: "",
            contact_email: "",
            contact_address: ""

        }

    }
    componentDidMount() {

        axios.post(AppURL.GetPhoneLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Phone'])[0];
                this.setState({
                    contact_number: JsonData['contact_number']
                });
            }

        }).catch(error => {

        });

        axios.post(AppURL.GetEmailLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Email'])[0];
                this.setState({
                    contact_email: JsonData['contact_email']
                });
            }

        }).catch(error => {

        });


        axios.post(AppURL.GetContactUsLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Contact'])[0];
                this.setState({
                    contact_address: JsonData['contact_address']
                });
            }

        }).catch(error => {

        });
    }
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>

                        <Col lg={3} md={3} sm={12} xs={12}>
                            <Logo />
                        </Col>
                        <Col lg={9} md={9} sm={12} xs={12}>
                            <Row>
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <div className="widget">
                                        <Row>
                                            <Col lg={2} md={2} sm={12} xs={12}>
                                                <i className="fa fa-headphones"></i>
                                            </Col>
                                            <Col lg={10} md={10} sm={12} xs={12}>
                                                <a href={`tel:${this.state.contact_number}`} className="font-12">Call us for more details</a>
                                                <div className="font-16"> {this.state.contact_number}</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <div className="widget">
                                        <Row>
                                            <Col lg={2} md={2} sm={12} xs={12}>
                                                <i className="fa fa-envelope"></i>
                                            </Col>
                                            <Col lg={10} md={10} sm={12} xs={12}>
                                                <a href={`mailto:${this.state.contact_email}`} className="font-12">Mail us for more details</a>
                                                <div className="font-16"> {this.state.contact_email}</div>
                                            </Col>
                                        </Row>
                                    </div>

                                </Col>
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <div className="widget">
                                        <Row>
                                            <Col lg={2} md={2} sm={12} xs={12}>
                                                <i className="fa fa-map"></i>
                                            </Col>
                                            <Col lg={10} md={10} sm={12} xs={12}>
                                                <a href="#" className="font-12">Our Location</a>
                                                <div className="font-13"> <div dangerouslySetInnerHTML={{ __html: this.state.contact_address }} /></div>
                                            </Col>
                                        </Row>
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default LogoWithAddress
